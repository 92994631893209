<template>
    <section class="content">
        <table class="table table-hover" ref="tablesuswa">
            <thead>
                <tr>
                    <th>Nama</th>
                    <th>Gender</th>
                    <th>Birth date</th>
                    <th>Email</th>
                    <th>No hp</th>
                    <th>No ktp</th>
                    <th>Sekolah</th>
                    <th>Jenis staff</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="card" style="width:100%">
                                  <div class="card-header d-flex p-0">
                                    <ul class="nav nav-pills mr-auto p-2">
                                      <li class="nav-item"><a class="nav-link active" href="#tab_1" data-toggle="tab">Umum</a></li>
                                      <li class="nav-item"><a class="nav-link" href="#tab_2" data-toggle="tab">Pendidikan</a></li>
                                      <li class="nav-item"><a class="nav-link" href="#tab_3" data-toggle="tab">Lain - Lain</a></li>
                                    </ul>
                                  </div><!-- /.card-header -->
                                  <div class="card-body">
                                    <div class="tab-content">
                                      <div class="tab-pane active" id="tab_1">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Username</label>
                                                <input type="hidden" name="row_id" value="" />
                                                <input id="username" class="form-control" v-model="form.username" type="text" name="username" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Group</label>
                                                <select v-model="form.role_id" class="form-control">
                                                    <option v-for="role in groups" v-bind:value="role.id">{{ role.label }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Display Name</label>
                                                <input id="name" class="form-control" v-model="form.display_name" type="text" name="i_name" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Email</label>
                                                <input id="email" class="form-control" v-model="form.email" type="text" name="i_email" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Password</label>
                                                <input id="pwd" class="form-control" value="" type="password" name="i_pwd" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Gender</label>
                                                <select v-model="form.gender" class="form-control">
                                                    <option v-for="item in genders" v-bind:value="item.id">{{ item.label }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Birth date</label>
                                                <datepicker v-model="form.birth_date" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Birht Place</label>
                                                <input id="birth_place" class="form-control" v-model="form.birth_place" type="text" name="i_birth_place" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Status Kawin</label>
                                                <select v-model="form.status_kawin" class="form-control">
                                                    <option v-bind:value="1">KAWIN</option>
                                                    <option v-bind:value="2">BELUM KAWIN</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Jumlah Anak</label>
                                                <input id="jumlah_anak" class="form-control" v-model="form.jumlah_anak" type="number" name="i_jumlah_anak" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No KTP</label>
                                                <input id="no_ktp" class="form-control" v-model="form.no_ktp" type="number" name="i_no_ktp" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No KK</label>
                                                <input id="no_kk" class="form-control" v-model="form.no_kk" type="number" name="i_no_kk" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">No HP</label>
                                                <input id="no_hp" class="form-control" v-model="form.no_hp" type="number" name="i_no_hp" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Alamat</label>
                                                <input id="alamat" class="form-control" v-model="form.alamat" type="text" name="i_alamat" />
                                            </div>
                                        </div>
                                      </div>
                                      <!-- /.tab-pane -->
                                      <div class="tab-pane" id="tab_2">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Pendidikan Terakhir</label>
                                                <input id="pendidikan_terakhir" class="form-control" v-model="form.pendidikan_terakhir" type="text" name="i_pendidikan_terakhir" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Sekolah</label>
                                                <datepicker v-model="form.lulus_sekolah" :options="{format:'yyyy', startView: 2,minViewMode: 2}" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Jurusan</label>
                                                <input id="jurusan_sekolah" class="form-control" v-model="form.jurusan_sekolah" type="text" name="i_jurusan_sekolah" />
                                            </div>
                                        </div>
                                      </div>
                                      <!-- /.tab-pane -->
                                      <div class="tab-pane" id="tab_3">
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Absensi</label>
                                                <input id="fisik_tinggi" class="form-control" v-model="form.fisik_tinggi" type="number" name="i_fisik_tinggi" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Mesin Absensi</label>
                                                <input id="fisik_berat" class="form-control" v-model="form.fisik_berat" type="number" name="i_fisik_berat" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Gaji Pokok</label>
                                                <input id="fisik_mata" class="form-control" v-model="form.fisik_mata" type="text" name="i_fisik_mata" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Unit</label>
                                                <select v-model="form.role_id" class="form-control">
                                                    <option v-for="role in groups" v-bind:value="role.id">{{ role.label }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Nama Bank</label>
                                                <input id="nama_bank" class="form-control" v-model="form.nama_bank" type="text" name="i_nama_bank" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Nomor Rekening</label>
                                                <input id="no_rekening" class="form-control" v-model="form.no_rekening" type="text" name="i_fisik_penyakit" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Atasnama</label>
                                                <input id="atasnama" class="form-control" v-model="form.atasnama" type="text" name="i_atasnama" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="control-label"> </label>
                                                <label class="checkbox control-label">
                                                <input value="1" checked="checked" type="checkbox" name="is_tentor" /> is_tentor</label>
                                            </div>
                                        </div>
                                      </div>
                                      <!-- /.tab-pane -->
                                    </div>
                                    <!-- /.tab-content -->
                                  </div><!-- /.card-body -->
                                </div>
                                <!-- ./card -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';

export default {
    name: 'Karyawanlist',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            units: [],
            genders: [
                {id: 'L', label: 'Laki-laki'},
                {id: 'P', label: 'Perempuan'}
            ],
            formTitle: 'Tambah User',
            form: {
                username : '',
                role_id : '',
                display_name : '',
                email : '',
                gender : '',
                birth_date : '',
                birth_place : '',
                alamat : '',
                no_ktp : '',
                no_kk : '',
                no_hp : '',
                asal_sekolah : '',
                jurusan_sekolah : '',
                lulus_sekolah : '',
                kuliah : '',
                fakultas : '',
                jurusan : '',
                semester : '',
                tujuan_dinas : '',
                fisik_tinggi : '',
                fisik_berat : '',
                fisik_mata : '',
                fisik_kacamata : '',
                fisik_kelainan : '',
                fisik_penyakit : '',
                nama_keluarga : '',
                gender2 : '',
                relation_type : '',
                birth_date2 : '',
                birth_place2 : '',
                education2 : '',
                job : '',
            }
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        //console.log('load initial data')
        authFetch('/users/groups')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.groups = js.data;
            })

        authFetch('/karyawan/units')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.units = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/masterdata/karyawan';
            console.log(data);
            if (this.method == 'PUT') urlSubmit = '/masterdata/karyawan/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tablesuswa, {
            "title": "List Data",
            "roles": this.$route.params.roles,
            "ajax": "/masterdata/karyawan",
            "columns": [
                { "data": "display_name" },
                { "data": "gender" },
                { "data": "birth_date" },
                { "data": "email" },
                { "data": "no_hp" },
                { "data": "no_ktp" },
                { "data": "sekolah" },
                { "data": "is_tentor" },
                { "data": "status" },
            ],
            filterBy: [0, 1, 2, 3, 4, 5, 6],
            "rowCallback": function(row, data) {
                $('td:eq(7)', row).html('<a class="badge badge-success" >' + data.is_tentor == 'f' ? 'Tentor':'' + '</a>');
                $('td:eq(8)', row).html('<a class="badge badge-success" >' + data.is_tentor == 'f' ? 'Tentor':'' + '</a>');
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Siswa';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Siswa';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/masterdata/siswa/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
</style>